export const form = {
  fvv: 1,
  pageHistory: 0,
  fbzx: "7882376862100732581",
  action: "e/1FAIpQLSf0Ou8YzwJSuHDjK3pLmawaqINqlrpg_3UvlX6f71CG7ItZvg",
  title: "Contact Me",
  description: null,
  fields: [
    {
      label: "Name",
      description: null,
      type: "SHORT_ANSWER",
      id: "90031984",
      required: true,
    },
    {
      label: "Email",
      description: null,
      type: "SHORT_ANSWER",
      id: "2101468196",
      required: true,
    },
    {
      label: "Phone",
      description: null,
      type: "SHORT_ANSWER",
      id: "379417988",
      required: false,
    },
    {
      label: "Message",
      description: null,
      type: "LONG_ANSWER",
      id: "1212579242",
      required: true,
    },
  ],
  fieldsOrder: { 90031984: 0, 379417988: 2, 1212579242: 3, 2101468196: 1 },
};

export const projects = [
  {
    title: "Time Token Generator",
    subtitle: "Flutter Google Maps APIY",
    description:
      "This is an application built on Flutter framework. it uses Google Maps API and I Calculates number of people going to a particular place and suggests time you can go to avoid overcrowding",
    image: "./img/tt.gif",
    link: "#",
  },
  {
    title: "Spotify Songs features Fetcher",
    subtitle:
      "It is built on NodeJs to fetch features of all songs in multiple Playlist",
    description:
      "It is Used to gather large data of songs features for different songs in spotify. It can be used as dataset for ML Projects",
    image: "./img/spotFeat.gif",
    link: "#",
  },
  {
    title: "Basic Chat App",
    subtitle: "Flutter and Firebase",
    description:
      "This application in bult using Flutter and Firebase NoSql Cloud Database. You can login or register usign Email in this app and then start send messages to each other",
    image: "./img/flashChat.gif",
    link: "#",
  },
  // {
  //   title: "Epic Todo App",
  //   subtitle: "React Hooks",
  //   description:
  //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium dolore rerum laborum iure enim sint nemo omnis voluptate exercitationem eius?",
  //   image: "./project-4.gif",
  //   link: "https://pythonbootcamp.com",
  // },
];
export const skills = [
  "ReactJS",
  "NodeJS",
  "NPM",
  "HTML5",
  "CSS",
  "Gitlab",
  "Redux",
  "Selenium",
  "GraphQL",
  "Firebase",
];
export const skillsDetails = {
  ReactJS: ["React Function Based Components", "React Router"],
  NodeJS: ["ExpressJS", "REST API", "AXIOS", "Puppeteer", "jsonwebtoken"],
  NPM: ["NPX", "NPM"],
  HTML5: ["Responsive Web Design"],
  CSS: ["CSS3, Tailwind CSS"],
  Selenium: ["Selenium with Python/Java"],
  Firebase: ["Firestore", "Firebase Hosting", "FIrebase Authentication"],
};
