import React, { useState } from "react";
import { BadgeCheckIcon, ChipIcon } from "@heroicons/react/solid";
import { skills, form } from "./data";
import { PaperClipIcon } from "@heroicons/react/solid";
import { GoogleFormProvider, useGoogleForm } from "react-google-forms-hooks";
import ShortAnswerInput from "./formsInput/ShortAnswerInput";
import LongAnswerInput from "./formsInput/LongAnswerInput";
import MySkills from "./MySkills";
import { Link } from "react-router-dom";
var bodyTextColor = "text-white";
var headingColor = "text-white";

export default function PortfolioHome() {
  const [isSkillDetails, setisSkillDetails] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const methods = useGoogleForm({ form });
  const onSubmit = async (data) => {
    await methods.submitToGoogleForms(data);
    // then do Something else like chage state
  };
  return (
    <div className="bg-gradient-to-l from-gray-700 to-gray-900">
      <section
        id="about"
        className="bg-up-sky2 bg-fixed	bg-contain	bg-center	h-screen 	bg-origin-content	bg-repeat"
      >
        <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center 	">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left leading-7	 mb-16 md:mb-0 items-center text-center bottom-0 right-0">
            <h1 className="title-font  min-w-max	tracking-normal	backdrop-blur-[4px]	font-PressStart2P	leading-7		px-2 py-5 rounded-xl	 sm:text-xl text-base mb-4 text-white absolute text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-2/3		text-shadow-md	">
              <p className="mb-1	">Hi, I'm Shubham.</p>
              <p className="mb-1	"> I love to develop amazing Apps. </p>
              <p className="mb-1	">
                I made this website to tell you{" "}
                <br className="lg:hidden  inline-block" />
                more about myself.
              </p>
            </h1>
            <p className="mb-8 leading-relaxed"></p>
            <div className="flex justify-center bottom-0 absolute my-5">
              <a
                href="#contact"
                className="inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
              >
                Work With Me
              </a>
              <Link
                to="/projects"
                className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
              >
                See My Past Work
              </Link>
            </div>
          </div>
          {/* <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="./coding.svg"
            />
          </div> */}
        </div>
      </section>
      <section id="skills">
        <div className="container px-5 py-10 mx-auto">
          <div className="text-center mb-20">
            <ChipIcon className="w-10 inline-block mb-4 text-white" />
            <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4 font-VT323">
              Skills &amp; Technologies
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-white">
              I have been working on Full Stack technologies since my college
              days. I have created Webapps based on ReactJS and expressJS and
              mobile based applications using flutter
            </p>
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            {skills.map((skill) => (
              <div key={skill} className="p-2 sm:w-1/2 w-full">
                <div
                  className="bg-gray-800 rounded flex p-4 h-full text-white items-center duration-300	"
                  onMouseOver={() => setisSkillDetails(skill)}
                  onMouseLeave={() => setisSkillDetails(false)}
                >
                  <BadgeCheckIcon className="text-green-400 w-6 h-6 flex-shrink-0 mr-4" />
                  <span className="title-font font-medium text-white">
                    <MySkills skill={skill} isSkillDetails={isSkillDetails} />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="contact">
        <div className="container px-10 py-10 mx-auto flex sm:flex-nowrap flex-wrap grid grid-cols-5 gap-6">
          <div className="bg-green-500 shadow overflow-hidden sm:rounded-lg md:col-span-3 col-span-5">
            <div className="px-4 py-5 sm:px-6 font-VT323  tracking-widest">
              <h3 className="text-3xl leading-6	font-medium		text-white">
                Contact Information
              </h3>
              <p className="mt-1 max-w-2xl text-xl font-medium text-gray-50">
                Personal details.
              </p>
            </div>
            <div className="border-t border-green-500">
              <dl>
                <div className=" bg-slate-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium  text-white">Full name</dt>
                  <dd className="mt-1 text-sm text-gray-50 sm:mt-0 sm:col-span-2">
                    Shubham Gupta
                  </dd>
                </div>
                <div className="bg-slate-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-white">
                    Application for
                  </dt>
                  <dd className="mt-1 text-sm text-gray-50 sm:mt-0 sm:col-span-2">
                    ReactJS / Node Developer
                  </dd>
                </div>
                <div className="bg-slate-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-white">
                    Email address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-50 sm:mt-0 sm:col-span-2">
                    gupta.shubhamhello@gmail.com
                  </dd>
                </div>
                <div className="bg-slate-800 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-white">Phone</dt>
                  <dd className="mt-1 text-sm text-gray-50 sm:mt-0 sm:col-span-2">
                    +91 700-685-6109
                  </dd>
                </div>
                {/* <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Salary expectation
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    ????
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim
                    incididunt cillum culpa consequat. Excepteur qui ipsum
                    aliquip consequat sint. Sit id mollit nulla mollit nostrud
                    in ea officia proident. Irure nostrud pariatur mollit ad
                    adipisicing reprehenderit deserunt qui eu.
                  </dd>
                </div> */}
                <div className="bg-slate-700 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-white">
                    Attachments
                  </dt>
                  <dd className="mt-1 text-sm  sm:mt-0 sm:col-span-2">
                    <ul
                      role="list"
                      className=" rounded-md bg-white text-gray-600 hover:text-gray-50 hover:bg-green-500 pointer-events-none divide-y divide-gray-200"
                    >
                      <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm ">
                        <div className="w-0 flex-1 flex items-center ">
                          <PaperClipIcon
                            className="flex-shrink-0 h-5 w-5"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate ">
                            CV.2022.pdf
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            href="https://www.dl.dropboxusercontent.com/s/4o48t1eqa769j83/CV.2022.pdf?dl=0"
                            className="font-medium text-green-500 hover:text-white  pointer-events-auto"
                          >
                            Download
                          </a>
                        </div>
                      </li>
                      {/* <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                        <div className="w-0 flex-1 flex items-center">
                          <PaperClipIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 flex-1 w-0 truncate">
                            coverletter_back_end_developer.pdf
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a
                            href="#"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Download
                          </a>
                        </div>
                      </li> */}
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <GoogleFormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="md:col-span-2 col-span-5"
            >
              <div className="shadow overflow-hidden sm:rounded-md mx-3">
                <div className="px-4 py-5 bg-slate-700 sm:p-6">
                  <div className="pb-3 text-white font-medium text-2xl	tracking-wide	font-VT323">
                    Have a question or Want to work together?
                  </div>

                  <div className="grid grid-cols-2 gap-3">
                    <div className="col-span-2 xl:col-span-1">
                      {/* <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-900"
                      >
                        Name
                      </label> */}
                      <ShortAnswerInput
                        placeholder="Name"
                        id={form.fields[0].id}
                        autoComplete="given-name"
                        className="placeholder:bold placeholder:text-slate-400 mt-1 py-1 px-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded"
                      />
                    </div>
                    <div className="col-span-2 xl:col-span-1">
                      {/* <label
                        htmlFor="Phone"
                        className={`block text-sm font-medium ${bodyTextColor}`}
                      >
                        Phone
                      </label> */}
                      <ShortAnswerInput
                        placeholder="Phone"
                        id={form.fields[2].id}
                        autoComplete="family-name"
                        className="placeholder:bold placeholder:text-slate-400 mt-1 py-1 px-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded"
                      />
                    </div>
                    <div className="col-span-2">
                      {/* <label
                        htmlFor="email-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label> */}
                      <ShortAnswerInput
                        type="text"
                        placeholder="Email Address"
                        id={form.fields[1].id}
                        autoComplete="email"
                        className="placeholder:bold placeholder:text-slate-400 mt-1 py-1 px-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded"
                      />
                    </div>
                    <div className="col-span-2">
                      {/* <label
                        htmlFor="message"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Message
                      </label> */}
                      <LongAnswerInput
                        placeholder="Message"
                        id={form.fields[3].id}
                        autoComplete="street-address"
                        className="placeholder:bold placeholder:text-slate-400 mt-1 py-1 px-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded"
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-slate-800 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600	 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </GoogleFormProvider>
        </div>
      </section>
    </div>
  );
}
