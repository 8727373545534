import React from "react";

import { useShortAnswerInput } from "react-google-forms-hooks";

export default function ShortAnswerInput({ id, placeholder, className }) {
  const { register } = useShortAnswerInput(id);

  return (
    <input
      type="text"
      placeholder={placeholder}
      className={className}
      {...register()}
    />
  );
}
