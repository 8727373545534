/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import {
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/react/outline";
import { useLocation } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PortfolioNavbar() {
  const location = useLocation();
  console.log();
  const navigation = [
    {
      name: "Home",
      href: "/home",
      current: location.pathname == "/home" ? true : false,
    },
    //   { name: "Team", href: "#", current: false },
    {
      name: "Projects",
      href: "/projects",
      current: location.pathname == "/projects" ? true : false,
    },
    // {
    //   name: "Certificates",
    //   href: "/certificates",
    //   current: location.pathname == "/certificates" ? true : false,
    // },
  ];
  const [upArrow, setUpArrow] = useState(true);
  return (
    <>
      {" "}
      <Disclosure as="nav" className="bg-gray-800 fixed inset-x-0 z-10	">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 sticky">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center">
                    <Link
                      className="block h-8 w-auto text-white text-2xl font-medium tracking-widest font-Pacifico mx-3"
                      to="/"
                    >
                      Protatto
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block px-3 py-2 rounded-md text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Menu as="div" className="mx-3 fixed bottom-3 right-3 ">
        <div>
          <Menu.Button className=" text-sm float-right  bg-white rounded">
            <span className="sr-only">Open user menu</span>
            {upArrow ? (
              <ChevronDoubleUpIcon
                className="h-9 w-9 rounded-full text-green-500"
                onClick={() => setUpArrow(false)}
              />
            ) : (
              <ChevronDoubleDownIcon
                className="h-9 w-9 rounded-full text-green-500"
                onClick={() => setUpArrow(true)}
              />
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-400"
          enterFrom="transform opacity-0 scale-0"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-400"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-0"
        >
          <Menu.Items className="origin-bottom-right relative mt-10   w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 flex justify-between	"
                  )}
                >
                  LinkedIn{" "}
                  <SocialIcon
                    network="linkedin"
                    className="-translate-y-0.5"
                    style={{ height: 25, width: 25 }}
                  />
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="https://github.com/shubham1358"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 flex justify-between	"
                  )}
                >
                  GitHub{" "}
                  <SocialIcon
                    network="github"
                    className="-translate-y-0.5"
                    style={{ height: 25, width: 25 }}
                  />
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700 flex justify-between	"
                  )}
                >
                  My Hobbies
                </a>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
