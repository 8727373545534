import React from "react";
import { skillsDetails } from "./data";
import { CSSTransition, SwitchTransition } from "react-transition-group";
export default function MySkills({ skill, isSkillDetails }) {
  var a = () => {
    if (
      (skillsDetails[skill] != undefined || skillsDetails[skill] != null) &&
      isSkillDetails == skill
    )
      return skillsDetails[skill].join(" ");
    else return skill;
  };

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          classNames="fade"
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          key={a()}
        >
          <div>{a()}</div>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}
