import React from "react";
import { Routes, Route } from "react-router-dom";
import PortfolioHome from "./PortfolioHome";
import PortfolioNavbar from "./PortfolioNavbar";
import Projects from "./Projects";
import Extra from "./Extra";
import "../../App.css";
export default function Portfolio() {
  return (
    <>
      <PortfolioNavbar />
      <Routes>
        <Route path="/" element={<PortfolioHome />} />
        <Route path="home" element={<PortfolioHome />} />
        <Route path="projects" element={<Projects />} />
        <Route path="extra" element={<Extra />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </>
  );
}
