import React from "react";

import { useLongAnswerInput } from "react-google-forms-hooks";

export default function LongAnswerInput({ id, placeholder, className }) {
  const { register } = useLongAnswerInput(id);

  return (
    <textarea
      type="text"
      placeholder={placeholder}
      className={className}
      {...register()}
    />
  );
}
